
  import { computed, defineComponent, onMounted, PropType, reactive, ref, toRefs, watchEffect, watch } from 'vue';
  import {
    MemberModalPO,
    MemberRole,
    MemberRole_Name,
    MemberPermission,
    BusinessMemberInfoLocal,
  } from '@/type/businessMember';
  import { BusinessMemberInfoVo, BusinessMemberNameListVo, DictionaryTypeEnum, DictionaryVo } from '@/type/api';
  import {
    addBusinessMember,
    getBusinessMemberInfo,
    getBusinessMemberNameList,
    updateBusinessMember,
    resetPwd,
    getHospitalInfo,
    getQwUserIdBindInfo,
  } from '@/services/manage';

  import { Option } from '@/type/common';
  import moment from 'moment';
  import { message } from 'ant-design-vue';
  import _, { debounce } from 'lodash';
  export default defineComponent({
    name: 'BusinessEditModal',
    props: {
      modalInfo: {
        type: Object as PropType<MemberModalPO>,
        required: true,
        defauult: () => ({ show: false }),
      },
    },
    emits: ['close'],
    setup(props, { emit }) {
      const formRef = ref();
      const formName = ref('');

      const formLoading = ref(false);
      const nameLoading = ref(false);
      const memberInfo = ref<Partial<BusinessMemberInfoLocal>>({ status: 1, orderWeight: 6 });
      const resetModal = ref(false);
      const selectJGs = ref([] as any[]);
      const selectJGData: any = ref([] as any[]);

      const isCreate = computed(() => {
        return props.modalInfo.type === 'create';
      });

      const organizations: any = ref([]);

      const selectedOrganization = ref<any | null>(null);
      const filteredOrganizations = ref<any[]>(organizations);
      const handleSearch = (value: any[]) => {
        getHospitalListData();
        selectJGs.value = value;
      };

      const onSearch = (value: any) => {
        formName.value = value;
        getHospitalListData();
        // selectJGs.value = value;
      };

      watch(
        () => memberInfo.value.hospitalIds,
        (newValue, old) => {
          if (newValue && newValue.length) {
            let selectArr: any = [];
            for (let key in newValue) {
              selectArr.push(newValue[key]);
            }
            selectJGs.value = selectArr;
          }
        },
        { immediate: true },
      );

      const value = ref<number>(6);

      // //创建时，【账号状态】默认为 启用
      // memberInfo.value.status = 1;
      // memberInfo.value.orderWeight =6;
      const Opts = reactive({
        /** 成员 */
        memberOpts: [] as BusinessMemberNameListVo[],
        /** 区域 */
        areaTree: [] as DictionaryVo<DictionaryTypeEnum.Province | DictionaryTypeEnum.City>[],
        /** 机构 */
        hospitalOpts: [] as Option[],
      });

      // 手机号校验
      const verifyPhoneNumber = async (rule, value) => {
        const phoneNumberRegex = /^1[3456789]\d{9}$/;
        if (!value) {
          return Promise.reject('请输入手机号');
        }
        if (!phoneNumberRegex.test(value)) {
          return Promise.reject('手机号格式不正确');
        }
      };
      const verifyQwUserId = async (rule, value) => {
        if (!value) return;
        const res = await getQwUserIdBindInfo({ qwUserId: value, prWorkNo: memberInfo.value.jinSaiId || '' });
        const { isBind, isUsable } = res || {};
        if (isBind) {
          return Promise.reject('该企微账号已被绑定');
        }
        if (!isUsable) {
          return Promise.reject('该企微账号为无效账号');
        }
      };
      const rules = {
        memberName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { required: true, message: '姓名长度不得超过30', trigger: 'blur', max: 30 },
        ],
        jinSaiId: [
          { required: true, message: '请输入工号', trigger: 'blur' },
          { required: true, message: '工号长度不得超过30', trigger: 'blur', max: 30 },
        ],
        status: [{ required: true }],
        orderWeight: [
          { required: true, type: 'number', message: '请输入权重', trigger: 'change' },
          {
            validator: (_, value) => {
              if (value < 0 || value > 50) {
                return Promise.reject('输入范围0到50');
              } else {
                return Promise.resolve();
              }
            },
            trigger: 'change',
          },
        ] as any[],
        role: [{ type: 'array', required: true, message: '请选择角色', trigger: 'change' }],

        hospitalIds: [{ type: 'array', required: true, message: '请选择机构', trigger: 'change' }],
        phoneNumber: [{ type: 'string', validator: verifyPhoneNumber, required: true, trigger: 'blur' }],
        qwUserId: [{ type: 'string', validator: verifyQwUserId, trigger: 'blur' }],
      };

      //要求权重只能输入正整数
      const onInputChange = (value: number) => {
        if (value < 0) {
          memberInfo.value.orderWeight = 0;
        } else if (value > 50) {
          memberInfo.value.orderWeight = 50;
        }
        // 确保输入的是整数
        memberInfo.value.orderWeight = Math.floor(value);
      };

      const getHospitalListData = () => {
        let hospitalIdListStr: any = memberInfo.value.hospitalIds;
        let params = {
          hospitalIdList: !formName.value ? hospitalIdListStr?.join(',') : '',
          hospitalName: formName.value,
        };
        getHospitalInfo(params).then((data) => {
          filteredOrganizations.value = data;
          selectJGData.value.push(...data);
        });
      };
      /**
       * 获取成员信息
       */
      const getMemberInfo = (memberId: number) => {
        formLoading.value = true;
        getBusinessMemberInfo({ id: memberId })
          .then((data) => {
            if (data) {
              const role = data.role.filter((item) => MemberRole_Name[MemberRole[item]]);
              let hospitalIds: any[] = data.hospital.map((item: any) => item.hospitalId);

              (selectJGs.value = hospitalIds),
                (memberInfo.value = {
                  ...data,
                  hospitalIds,
                  role,
                  dateRange: [moment(data.startDate), moment(data.endDate)],
                });
              getHospitalListData();
            } else {
              message.error('获取成员详情失败');
              handleClose();
            }
          })
          .finally(() => {
            formLoading.value = false;
          });
      };
      /**
       * 获取成员名称列表
       */
      const getMemberList = debounce((memberName?: string) => {
        nameLoading.value = true;
        getBusinessMemberNameList(memberName)
          .then((data) => {
            Opts.memberOpts = data;
          })
          .finally(() => {
            nameLoading.value = false;
          });
      }, 300);

      /**
       * 处理提交参数
       */
      const getParams = () => {
        const params = Object.assign({}, memberInfo.value);

        return params as BusinessMemberInfoVo;
      };
      /**
       * 处理成员名称搜索
       */
      const handleMenberNameChange = (value: string) => {
        getMemberList(value);
      };
      /**
       * 处理成员名称选择
       */
      const handleMemberSelect = (value: string, option: BusinessMemberNameListVo) => {
        memberInfo.value.id = option.id;
        memberInfo.value.memberName = option.memberName;
        memberInfo.value.jinSaiId = option.jinSaiId;
      };

      // 打开重置密码弹窗
      const openResetModal = () => {
        resetModal.value = true;
      };
      const resetPassword = () => {
        formLoading.value = true;
        resetPwd({ id: memberInfo.value.id }).then((res: any) => {
          if (res) {
            formLoading.value = false;
            handleClose();
            message.success('账号的密码己重置成功');
          }
        });
      };
      /**
       * 关闭弹窗
       */
      const handleClose = (isRefresh = false) => {
        memberInfo.value = {};
        emit('close', isRefresh);
      };

      const getRegionArrSet = (regionArr: any[]) => {
        return regionArr.reduce((acc, current) => {
          const existingItem = acc.find((item) => item.provinceId == current.provinceId);
          if (!existingItem) {
            acc.push(current);
          }
          return acc;
        }, []);
      };
      const getHospitalArrSet = (regionArr: any[]) => {
        return regionArr.reduce((acc, current) => {
          const existingItem = acc.find((item) => item.hospitalId == current.hospitalId);
          if (!existingItem) {
            acc.push(current);
          }
          return acc;
        }, []);
      };
      /**
       * 提交信息
       */
      const handleSubmit = () => {
        formLoading.value = true;
        if (formRef.value) {
          formRef.value
            .validate()
            .then(() => {
              const params: any = getParams();

              // 筛选出list中存在的hospitalId对应的organizations
              const matchedOrganizations = selectJGData.value.filter((org) => selectJGs.value.includes(org.hospitalId));
              let regionArr = matchedOrganizations.map((org) => ({
                provinceId: org.provinceId,
                provinceName: org.provinceName,
                cityId: org.cityId,
                cityName: org.cityName,
              }));
              let hospitalArr = matchedOrganizations.map((org) => ({
                hospitalId: org.hospitalId,
                hospitalName: org.hospitalName,
              }));
              let region = getRegionArrSet(regionArr);
              let hospital = getHospitalArrSet(hospitalArr);
              // 构建新的obj对象
              let hospitalObj = {
                region,
                hospital,
              };

              let newParams = { ...params, ...hospitalObj };
              delete newParams.hospitalIds;
              let promise: Promise<boolean> | null = null;

              if (isCreate.value) {
                promise = addBusinessMember(newParams);
              } else {
                promise = updateBusinessMember(newParams);
              }
              if (promise) {
                return promise.then((result) => {
                  if (result) {
                    message.success(isCreate.value ? '新增成功' : '更新成功');
                    handleClose(true);
                  }
                  formLoading.value = false;
                });
              }
            })
            .catch(() => {
              formLoading.value = false;
            });
        }
      };
      watchEffect(() => {
        if (props.modalInfo.show) {
          if (props.modalInfo.type === 'edit') {
            if (props.modalInfo.member) {
              getMemberInfo(props.modalInfo.member.id);
            }
          }
        }
      });
      onMounted(async () => {
        await getMemberList();
        (await props.modalInfo.type) != 'edit' && getHospitalListData();
      });
      return {
        formRef,
        isCreate,
        formLoading,
        nameLoading,
        rules,
        ...toRefs(Opts),
        labelCol: { span: 2 },
        wrapperCol: { span: 20 },
        memberInfo,
        MemberRole,
        MemberRole_Name,
        MemberPermission,
        handleMemberSelect,
        handleMenberNameChange: _.debounce(handleMenberNameChange),
        handleClose,
        handleSubmit,
        resetModal,
        openResetModal,
        resetPassword,
        selectedOrganization,
        filteredOrganizations,
        handleSearch,
        onSearch,
        value,
        onInputChange,
      };
    },
  });
