import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';

/**
 * 注射频率工具类
 */
export default class ActualDeliveryPriceHelper {
  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): [boolean, string] {
    const actualDeliveryPrice = modelRef['actualDeliveryPrice'];
    const isDiscount = modelRef['isDiscount'];
    const deliveryPrice = modelRef['deliveryPrice'];
    console.log('actualDeliveryPrice', actualDeliveryPrice);
    console.log('deliveryPriceModel', deliveryPrice);
    console.log('isDiscount', isDiscount);
    console.log('modelRef', modelRef);
    if (+actualDeliveryPrice < +deliveryPrice && isDiscount === '否') {
      return [true, '请确认是否优惠购药'];
    }
    if (+actualDeliveryPrice >= +deliveryPrice && isDiscount === '是') {
      return [true, '请确认是否优惠购药'];
    }
    return [false, ''];
  }
}
