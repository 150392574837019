
  import { computed, defineComponent, onMounted, PropType, reactive, ref, toRefs, watchEffect, UnwrapRef } from 'vue';
  import { MemberModalPO, MemberRole, MemberRole_Name, MemberPermission } from '@/type/businessMember';
  import { BusinessMemberInfoVo, BusinessMemberNameListVo, DictionaryTypeEnum, DictionaryVo } from '@/type/api';
  import { updateHospitalInfo, getHospitalLabelSpecList } from '@/services/manage';
  import { Option } from '@/type/common';
  import { message } from 'ant-design-vue';
  export default defineComponent({
    name: 'BusinessDetailModal',
    props: {
      modalInfo: {
        type: Object as PropType<MemberModalPO>,
        required: true,
        default: () => ({ show: false }),
      },
    },
    emits: ['close'],
    setup(props, { emit }) {
      const formRef = ref();
      const formLoading = ref(false);
      const nameLoading = ref(false);
      const isEdit = computed(() => (props.modalInfo.type === 'edit' ? true : false));

      const memberInfo = ref<Partial<any>>({
        hospitalName: null,
        provinceName: null,
        cityName: null,
        hospitalType: null,
        externalChiasma: null,
        category1: null,
        specialCertificate: null,
        remark: null,
        superflowDirection: null,
        category2: null,
      });
      const resetModal = ref(false);
      const isCreate = computed(() => {
        return props.modalInfo.type === 'create';
      });

      const selectedOrganization = ref<any | null>(null);
      const specList = ref([]);
      const value = ref<number>(6);
      const Opts = reactive({
        /** 成员 */
        memberOpts: [] as BusinessMemberNameListVo[],
        /** 区域 */
        areaTree: [] as DictionaryVo<DictionaryTypeEnum.Province | DictionaryTypeEnum.City>[],
        /** 机构 */
        hospitalOpts: [] as Option[],
      });

      const rules = {
        hospitalName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        provinceName: [{ required: true, message: '请输入工号', trigger: 'blur' }],
        cityName: [{ required: true }],
        hospitalType: [{ required: true, type: 'string', message: '请选择医院类型', trigger: 'change' }] as any[],
        externalChiasma: [{ required: true, type: 'number', message: '请选择是否儿外交叉医院', trigger: 'change' }],
        category1: [{ required: true, type: 'array', message: '请选择规格1', trigger: 'change' }],
        specialCertificate: [{ required: true, type: 'number', message: '请选择是否特殊凭证', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入特殊凭证备注', trigger: 'blur' }],
        superflowDirection: [{ required: true, message: '请选择是否超流向', type: 'number', trigger: 'blur' }],
        category2: [{ required: true, message: '请选择规格2', type: 'array', trigger: 'blur' }],
      };

      //要求权重只能输入正整数
      const onInputChange = (value: number) => {
        if (value < 0) {
          memberInfo.value.orderWeight = 0;
        } else if (value > 50) {
          memberInfo.value.orderWeight = 50;
        }
        // 确保输入的是整数
        memberInfo.value.orderWeight = Math.floor(value);
      };

      /**
       * 处理提交参数
       */
      const getParams = () => {
        const params = Object.assign({}, memberInfo.value);
        return params as BusinessMemberInfoVo;
      };

      // 打开重置弹窗
      const openResetModal = () => {
        resetModal.value = true;
      };
      const resetForm = () => {
        memberInfo.value.externalChiasma = 0;
        memberInfo.value.category1 = [];
        memberInfo.value.specialCertificate = 0;
        memberInfo.value.remark = '';
        memberInfo.value.superflowDirection = 0;
        memberInfo.value.category2 = [];
        resetModal.value = false;
        message.success('重置成功');
      };
      /**
       * 关闭弹窗
       */
      const handleClose = (isRefresh = false) => {
        memberInfo.value = {};
        emit('close', isRefresh);
      };

      /**
       * 提交信息
       */
      const handleSubmit = () => {
        formLoading.value = true;
        if (formRef.value) {
          formRef.value
            .validate()
            .then(() => {
              const params: any = getParams();
              let {
                id,
                hospitalId,
                remark,
                category1,
                category2,
                externalChiasma,
                specialCertificate,
                superflowDirection,
              } = params;
              updateHospitalInfo({
                id,
                hospitalId,
                remark,
                category1,
                category2,
                externalChiasma,
                specialCertificate,
                superflowDirection,
              }).then((result) => {
                if (result) {
                  message.success('提交成功');
                  handleClose(true);
                } else {
                  message.error('提交失败');
                }
                formLoading.value = false;
              });
            })
            .catch(() => {
              formLoading.value = false;
            });
        }
      };
      const clearParam = (e, key) => {
        if (!e.target.value) {
          switch (key) {
            case 'externalChiasma':
              memberInfo.value.category1 = [];
              break;
            case 'specialCertificate':
              memberInfo.value.remark = '';
              break;
            case 'superflowDirection':
              memberInfo.value.category2 = [];
              break;
          }
        }
      };
      const getSpecList = () => {
        getHospitalLabelSpecList().then((res) => {
          specList.value = res;
        });
      };
      watchEffect(() => {
        if (props.modalInfo.show) {
          if (props.modalInfo.member) {
            memberInfo.value = props.modalInfo.member;
            !memberInfo.value.category1 && (memberInfo.value.category1 = []);
            !memberInfo.value.category2 && (memberInfo.value.category2 = []);
          }
        }
      });
      onMounted(async () => {
        getSpecList();
      });
      return {
        formRef,
        isCreate,
        formLoading,
        nameLoading,
        rules,
        ...toRefs(Opts),
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        memberInfo,
        MemberRole,
        MemberRole_Name,
        MemberPermission,
        handleClose,
        handleSubmit,
        resetModal,
        openResetModal,
        resetForm,
        selectedOrganization,
        specList,
        value,
        onInputChange,
        clearParam,
        getSpecList,
        isEdit
      };
    },
  });
