import http, { requestBooleanHandler, requestHandler } from './http';
import qs from 'qs';

import {
  BaseRes,
  ListResData,
  RecordTaskListQO,
  RecordTaskVo,
  SubjectSearchQO,
  SubjectSearchVO,
  Role,
  TransferRecordTaskQo,
  ProjectMemberVo,
  ProjectMemberQo,
  RmBaseListQo,
  BusinessMemberListQo,
  BusinessMemberListVo,
  BusinessMemberNameListVo,
  BusinessMemberInfoVo,
  BusinessMemberInfoQo,
  QwUserIdBindInfoVo,
} from '@/type/api';

const serviceUrl = '';
type ServiceRes<T> = Promise<BaseRes<T>>;
export function getRecordTaskList(params: RecordTaskListQO): Promise<ListResData<RecordTaskVo>> {
  return requestHandler<ListResData<RecordTaskVo>>({
    service: () => http.get('/recordTask/list', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });

  // http.get(serviceUrl + '/recordTask/list', {
  //   params,
  // });
}
export function getTransRecordList(params: RecordTaskListQO): Promise<ListResData<RecordTaskVo>> {
  return requestHandler<ListResData<RecordTaskVo>>({
    service: () => http.get('/recordTask/transferList', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
}
export function transRecordTask(params: TransferRecordTaskQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/recordTask/transfer', params),
    defaultValue: false,
    errorMessage: '转移失败',
  });

  // return http.post(serviceUrl + '/recordTask/transfer', params);
}
export function getAllRoleByProject(): ServiceRes<ListResData<Role>> {
  return http.get(serviceUrl + '/permissionRole/getAllRoleByProject');
}

export function deleteProjectMember(params: { id: string; userId: string }): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/permission/deleteProjectMember', params),
    defaultValue: false,
    errorMessage: '删除失败',
  });
  // return http.get(serviceUrl + '/permission/deleteProjectMember');
}

export function getSubjectList(params: SubjectSearchQO): Promise<ListResData<SubjectSearchVO>> {
  return requestHandler<ListResData<SubjectSearchVO>>({
    service: () => http.get('/form/searchSubject', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });

  // http.get(serviceUrl + '/form/searchSubject', {
  //   params,
  // });
}
export function getProjectMemberListWeb(params: ProjectMemberQo): Promise<ListResData<ProjectMemberVo>> {
  return requestHandler<ListResData<ProjectMemberVo>>({
    service: () => http.get('/permission/projectMemberListWeb', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
  // return http.get(serviceUrl + '/permission/projectMemberListWeb', {
  //   params,
  // });
}

export function createTaskCenterExport(params: RmBaseListQo<RecordTaskListQO>): Promise<ListResData<RecordTaskVo>> {
  return requestHandler<ListResData<RecordTaskVo>>({
    service: () => http.get('/exportCenter/export/recordTask', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });

  // http.get(serviceUrl + '/recordTask/list', {
  //   params,
  // });
}

// 获取商务成员列表
export function getBusinessMemberList(params: BusinessMemberListQo): Promise<ListResData<BusinessMemberListVo>> {
  return requestHandler<ListResData<BusinessMemberListVo>>({
    service: () => http.get('/member/list', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
}

// 新增成员-获取成员名称列表
export function getBusinessMemberNameList(memberName?: string): Promise<Array<BusinessMemberNameListVo>> {
  return requestHandler<Array<BusinessMemberNameListVo>>({
    service: () => http.get('/member/name/list', { params: { memberName } }),
    defaultValue: [],
    errorMessage: '获取列表失败',
  });
}

// 添加成员信息
export function addBusinessMember(params: BusinessMemberInfoVo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/member/add', params),
    defaultValue: false,
    errorMessage: '新增失败',
  });
}

export function getHospitalInfo(params?: any): Promise<string[]> {
  return requestHandler<string[]>({
    service: () => http.get('/dictionary/getHospitalInfo?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '查询失败',
  });
}
// 更新成员信息
export function updateBusinessMember(params: BusinessMemberInfoVo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/member/update', params),
    defaultValue: false,
    errorMessage: '更新失败',
  });
}
// 重置密码
export function resetPwd(params): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/member/resetPwd', params),
    defaultValue: false,
    errorMessage: '重置失败',
  });
}
// 删除成员
export function deleteBusinessMember(params: { id: number; deleteInfo: string }): Promise<boolean> {
  return requestHandler<boolean>({
    service: () => http.get('/member/delete', { params }),
    defaultValue: false,
    errorMessage: '删除失败',
  });
}
// 停用成员
export function disableBusinessMember(params: { id: number }): Promise<boolean> {
  return requestHandler<boolean>({
    service: () => http.post('/member/disable', params),
    defaultValue: false,
    errorMessage: '停用失败',
  });
}
// 启用成员
export function enableBusinessMember(params: { id: number }): Promise<boolean> {
  return requestHandler<boolean>({
    service: () => http.post('/member/enable', params),
    defaultValue: false,
    errorMessage: '启用失败',
  });
}

// 获取成员信息
export function getBusinessMemberInfo(params: BusinessMemberInfoQo): Promise<BusinessMemberInfoVo | null> {
  return requestHandler<BusinessMemberInfoVo | null>({
    service: () => http.get('/member/getDetail', { params }),
    defaultValue: null,
    errorMessage: '获取成员信息失败',
  });
}

// 获取字典列表
export function getDictionaryList(params: { type: string; jinsaiParentId?: any }): Promise<any | null> {
  return requestHandler<BusinessMemberInfoVo | null>({
    service: () => http.get('/dictionary/list', { params }),
    defaultValue: null,
    errorMessage: '获取字典信息失败',
  });
}

// 获取成员绑定信息
export function getQwUserIdBindInfo(params: {
  qwUserId: string;
  prWorkNo?: string;
}): Promise<QwUserIdBindInfoVo | null> {
  return requestHandler<QwUserIdBindInfoVo | null>({
    service: () => http.get('member/queryQwUserIdBindInfo', { params }),
    defaultValue: null,
    errorMessage: '获取企微账号信息失败',
  });
}

// 获取医院标签列表
export function getHospitalLabelList(params?: any): Promise<boolean> {
  return requestHandler({
    service: () => http.post('/hospitalLabel/list', params),
    defaultValue: false,
    errorMessage: '查询失败',
  });
  // return http.get(serviceUrl + '/permission/deleteProjectMember');
}

// 医院扩展信息列表-药品规格枚举
export function getHospitalLabelSpecList(): Promise<any | null> {
  return requestHandler({
    service: () => http.get('/hospitalLabel/specList'),
    defaultValue: [],
    errorMessage: '获取规格信息失败',
  });
}

// 医院标签保存
export function updateHospitalInfo(params?: any): Promise<boolean> {
  return requestHandler({
    service: () => http.post('/hospitalLabel/updateHospitalInfo', params),
    defaultValue: false,
    errorMessage: '保存失败',
  });
}

// 医院扩展信息小红点标志
export function hospitalLabelCancelRedPoint(params?: any): Promise<boolean> {
  return requestHandler({
    service: () => http.post(`/hospitalLabel/cancelRedPoint?hospitalId=${params.hospitalId}`),
    defaultValue: false,
    errorMessage: '已读失败',
  });
}