
  import dictionary from '@/lib/dictionary';
  import { PlusCircleOutlined } from '@ant-design/icons-vue';
  import {
    BusinessMemberListQo,
    BusinessHospitalListVo,
    BusinessMemberListVo,
    DictionaryTypeEnum,
    RmBaseListQo,
  } from '@/type/api';
  import { Column, Option, TableState } from '@/type/common';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import { computed, defineComponent, onMounted, reactive, ref, toRefs, watchEffect } from 'vue';
  import { useRoute } from 'vue-router';
  import { MemberModalPO, MemberPermission } from '@/type/businessMember';
  import EditModal from './Edit.vue';
  import DeleteModal from './Delete.vue';
  import {
    getBusinessMemberList,
    disableBusinessMember,
    enableBusinessMember,
    getHospitalInfo,
  } from '@/services/manage';
  import { message } from 'ant-design-vue';
  type BusinessMemberQuery = Omit<RmBaseListQo<BusinessMemberListQo>, 'memberType'>;
  export default defineComponent({
    name: 'BusinessMemberList',
    components: { ManageListFrame, CustomTable, EditModal, DeleteModal, PlusCircleOutlined },
    setup() {
      const route = useRoute();
      const isLongTerm = computed(() => (route.name === 'longTermMember' ? true : false));
      const editModal = ref<MemberModalPO>({ show: false });
      const deleteModal = ref<MemberModalPO>({ show: false });
      // 定义0到50的选项数组
      const options = Array.from({ length: 51 }, (_, i) => ({
        id: i,
        name: i.toString(),
      }));

      const organizations: any = ref([]);
      const query = reactive<BusinessMemberQuery>({});

      const visible = ref(false);
      const isDisabled = ref(false);
      const userId: any = ref();
      const opts = reactive({
        provinceOpts: [] as Option[], // 省 下拉框
        roleList: [
          {
            name: '患者用药审核员',
            value: 'RECORDER',
          },
          {
            name: '平台管理员',
            value: 'PLATFORM_ADMIN',
          },
          {
            name: 'GH-区域管理员',
            value: 'REGION_ADMIN',
          },
        ] as any[], //角色下拉框
        stateOpts: [
          {
            name: '启用',
            value: 1,
          },
          {
            name: '停用',
            value: 5,
          },
        ] as any[], // 状态下拉框
      });
      const handleOrganizationChange = (value: string) => {
        console.log('value,,,', value);
        const selectedOrg = organizations.value.find((org: any) => org.hospitalId === value);
        if (selectedOrg) {
          query.provinceId = selectedOrg.provinceId;
          query.cityId = selectedOrg.cityId;
          query.hospitalId = selectedOrg.hospitalId;
          query.provinceName = selectedOrg.provinceName;
          query.cityName = selectedOrg.cityName;
          query.hospitalName = selectedOrg.hospitalName;
          // const params: BusinessHospitalListVo = {
          //   hospitalName: selectedOrg.hospitalName,
          //   provinceId: selectedOrg.provinceId,
          //   cityId: selectedOrg.cityId,
          // };
          getHospitalListData();
        } else {
          query.provinceName = undefined;
          query.cityName = undefined;
          query.hospitalName = undefined;
          query.provinceId = undefined; //省id
          query.cityId = undefined; //城市
          query.hospitalId = undefined; //机构id
        }
        getHospitalListData();
        handleSearch();
      };
      const getColumns = () => {
        let columns: Column[] = [];
        const baseColumns: Column[] = [
          {
            title: '姓名',
            dataIndex: 'name',
            width: 80,
            slots: {
              customRender: 'name',
            },
          },
          {
            title: '工号',
            dataIndex: 'workNum',
            width: 80,
            ellipsis: true,
            slots: {
              customRender: 'workNum',
            },
          },
          {
            title: '角色',
            dataIndex: 'role',
            ellipsis: true,
            width: 120,
            slots: {
              customRender: 'role',
            },
          },
          {
            title: '机构',
            dataIndex: 'hospital',
            width: 120,
            ellipsis: true,
            slots: {
              customRender: 'hospital',
            },
          },
          {
            title: '辖区',
            dataIndex: 'region',
            width: 120,
            ellipsis: true,
            slots: {
              customRender: 'region',
            },
          },
          { title: '手机号', dataIndex: 'phoneNumber', width: 100, ellipsis: true },
          { title: '权重', dataIndex: 'orderWeight', width: 100, ellipsis: true },
          { title: '企微账号', dataIndex: 'qwUserId', width: 100, ellipsis: true },
          {
            title: '状态',
            dataIndex: 'status',
            width: 50,
            ellipsis: true,
            slots: {
              customRender: 'status',
            },
          },

          {
            title: '操作',
            dataIndex: 'action',
            width: 200,
            slots: {
              customRender: 'actions',
            },
          },
        ];
        // 长期成员 列表变更
        if (isLongTerm.value) {
          baseColumns.forEach((column) => {
            if (column.dataIndex === 'region') {
              column.title = '区域';
            }
            if (!['effectiveTime', 'action'].includes(column.dataIndex)) {
              columns.push(column);
            }
          });
        } else {
          columns = baseColumns;
        }
        return columns;
      };
      const tableState = reactive(
        new TableState({
          dataSource: [] as BusinessMemberListVo[],
          columns: getColumns(),
          key: 'id',
        }),
      );

      /**
       * 获取成员列表
       */
      const getData = () => {
        //过滤对象中的假值，但不包括0。
        const filteredObj = Object.fromEntries(
          Object.entries(query).filter(([key, value]) => value != '' && value != null && value != undefined),
        );
        const params: BusinessMemberListQo = {
          ...filteredObj,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
          memberType: isLongTerm.value ? 0 : 1,
        };
        delete params.provinceName;
        delete params.cityName;
        delete params.hospitalName;
        console.log('getData', params);
        getBusinessMemberList(params).then((data) => {
          tableState.pagination.total = data.total;
          let dataList = JSON.parse(JSON.stringify(data.content));
          tableState.dataSource = dataList;
        });
      };
      const getHospitalListData = () => {
        let params = {
          hospitalName: query.hospitalName,
          provinceId: query.provinceId,
          cityId: query.cityId,
        };
        getHospitalInfo(params).then((data) => {
          console.log('getHospitalListData', data);
          organizations.value = data;
          // if (!modelRef.epenBatchNumber) {
          //   modelRef.epenBatchNumber = data[0];
          // }
          // epenBatchNumber.value.onFieldChange();
        });
      };
      /**
       * 清空搜索框中内容
       */
      const clearQuery = () => {
        query.memberName = undefined; //成员
        query.status = undefined; //账号状态
        query.role = undefined; //角色
        query.orderWeight = undefined; //权重
        query.workNum = undefined; //工号
        query.provinceName = undefined; //省
        query.cityName = undefined; //城市
        query.hospitalName = undefined; //机构
        query.provinceId = undefined; //省id
        query.cityId = undefined; //城市id
        query.hospitalId = undefined; //机构id
        getData();
        getHospitalListData();
      };

      /**
       * 处理 搜索
       */
      const handleSearch = () => {
        tableState.pagination.current = 1;
        getData();
      };
      /**
       * 处理 搜索
       */
       const handleBlur  = (e) => {
        console.log('handleBlur',e);

      };      /**
       * 处理 搜索
       */
      const handleFocus = (e) => {
        console.log('focus',e);

      };
         /**
       * 处理 搜索
       */
       const onSearch = (value) => {
        console.log('输入框的值:', value);
     query.hospitalName=value

      // 在这里可以根据value进行你需要的操作
      getHospitalListData();

      };
      /**
       * Table 换页
       */
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData();
      };

      /**
       *  关闭成员弹窗
       */
      const closeEditModal = (isReSearch: boolean) => {
        editModal.value = {
          show: false,
        };
        if (isReSearch) {
          handleSearch();
        }
      };

      /**
       *  打开添加成员弹窗
       */
      const openAddModal = () => {
        editModal.value = {
          show: true,
          type: 'create',
        };
      };

      /**
       *  打开更新成员弹窗
       */
      const openEditModal = (record: BusinessMemberListVo) => {
        editModal.value = {
          show: true,
          type: 'edit',
          member: record,
        };
      };

      /**
       *  打开删除成员弹窗
       */
      const openDeleteModal = (record: BusinessMemberListVo) => {
        deleteModal.value = {
          show: true,
          member: record,
        };
      };

      /**
       * 关闭删除成员弹窗
       */
      const closeDelteModal = (isRefresh: boolean) => {
        deleteModal.value = {
          show: false,
        };
        if (isRefresh) {
          handleSearch();
        }
      };
      /**
       * 打开状态修改弹窗
       */
      const openStatusModal = (status, userInfo) => {
        visible.value = true;
        isDisabled.value = status;
        userId.value = userInfo.id;
      };
      /**
       * 修改状态
       */
      const editStatus = () => {
        if (isDisabled.value) {
          disableBusinessMember({ id: userId.value }).then((res: any) => {
            if (res.ok) {
              message.success('停用成功');
              getData();
              visible.value = false;
            }
          });
        } else {
          enableBusinessMember({ id: userId.value }).then((res: any) => {
            if (res.ok) {
              message.success('启用成功');
              getData();
              visible.value = false;
            }
          });
        }
      };
      /**
       * 页面挂载时 获取省列表
       */
      onMounted(() => {
        clearQuery();
        getData();
        getHospitalListData();
      });
      /**
       * 重置按钮重置
       */
      const resetting = () => {
        clearQuery();
      };

      return {
        query,
        isLongTerm,
        tableState,
        editModal,
        deleteModal,
        ...toRefs(opts),
        handleSearch,
        handlePage,
        openAddModal,
        openEditModal,
        closeEditModal,
        openDeleteModal,
        closeDelteModal,
        MemberPermission,
        visible,
        openStatusModal,
        isDisabled,
        editStatus,
        handleOrganizationChange,
        organizations,
        options,
        resetting,
        handleBlur,
        handleFocus,
        onSearch
      };
    },
  });
