/**
 * 添加菜单步骤：
 * 1: /route 文件夹下 添加 vue-route 相关信息(确定path、name、文件位置)
 * 2: 与产品确认菜单对应的角色，并修改 menuRoleaConifg添加 route.name
 * 3：与产品确定菜单位置在哪个大类下，并修改 BaseMenuGroup
 *  - 注意：每一个 menuItem 需要有 key、title、show (具体含义详见 MenuGroup)
 *
 * ！！！！ 注意 vue-route 中的 name \path 务必与 menuRoleaConifg add追加的菜单名称，与 BaseMenuGroup 中的 key 保持一致
 *
 * 最终 menuGroupList 会在 ManageLayout 中进行渲染
 */
import { MemberRole } from '@/type/businessMember';
import { MenuGroup } from '@/type/menu';
import { computed } from 'vue';
import { state } from './state';

/**
 * 角色可使用的菜单
 * */
const menuRoleaConifg = computed(() => {
  const temp: { g1: Set<string>; g2: Set<string>; g3: Set<string> } = { g1: new Set(), g2: new Set(), g3: new Set() };
  state.userInfo?.role.forEach((item) => {
    console.log(state.userInfo);
    switch (item.enname) {
      /** 2 - GH-录入员 */
      case MemberRole.RECORDER:
        temp.g1.add('taskCenter');
        temp.g1.add('patient');
        temp.g1.add('formDel');
        temp.g1.add('saleImportCheck');
        temp.g1.add('saleDataManage');
        temp.g1.add('patientImportCheck');
        temp.g3.add('problemFeedback');
        // temp.g2.add('shortTermMember');

        break;
      /** 3- GH-区域管理员 */
      case MemberRole.REGION_ADMIN:
        temp.g1.add('taskCenter');
        temp.g1.add('patient');
        temp.g1.add('formDel');
        temp.g1.add('saleImportCheck');
        temp.g1.add('saleDataManage');
        temp.g3.add('problemFeedback');
        temp.g1.add('patientImportCheck');
        // temp.g2.add('shortTermMember');

        break;
      /** 4 - GH-平台管理员 */
      case MemberRole.PLATFORM_ADMIN:
        temp.g1.add('taskCenter');
        temp.g1.add('patient');
        temp.g1.add('formDel');
        temp.g1.add('saleImportCheck');
        temp.g1.add('saleDataManage');
        temp.g3.add('problemFeedback');
        temp.g1.add('patientImportCheck');
        temp.g2.add('shortTermMember');
        temp.g2.add('hospitalLabelList');
        break;
      /** 5 - GH-用药划分 */
      // case MemberRole.SALES_DIVISION:
      //   // temp.g2.add('saleGH');
      //   // temp.g2.add('saleDivisionRulesConfig');
      //   // temp.g2.add('SaleDivisionHistory');
      //   break;
      /** 6 - 营养品-录入员 */
      // case MemberRole.NUTRITION_RECORDER:
      //   // temp.g1.add('NourishmentSale');
      //   break;
      /** 7 - 营养品-用药划分 */
      // case MemberRole.NUTRITION_SALES_DIVISION:
      //   // temp.g2.add('saleNourishment');
      //   break;
      /** 8 - 线下-录入员*/
      // case MemberRole.OFFLINE_RECORDER:
      //   // temp.g1.add('GHOfflineSale');
      //   break;
      /** 9 - 线下-用药划分*/
      // case MemberRole.OFFLINE_SALES_DIVISION:
      //   break;
      /** 10 - 线下-用药划分*/
      // case MemberRole.OFFLINE_SALES_AUDIT:
      // temp.g1.add('GHOfflineAudit');
      // break;
    }
  });
  // if (state.userInfo?.export === 1) {
  temp.g1.add('exportCenter');
  // }
  return temp;
});

/**
 * 基础菜单
 */
const BaseMenuGroup: MenuGroup[] = [
  {
    key: 'g1',
    title: '工作区',
    children: [
      {
        key: 'taskCenter',
        title: '任务中心',
        icon: 'CheckSquareOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('taskCenter'),
      },
      {
        key: 'patient',
        title: '患者管理',
        icon: 'SolutionOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('patient'),
      },
      {
        key: 'formDel',
        title: '表单删除管理',
        icon: 'DeleteOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('formDel'),
      },
      // {
      //   key: 'GHOfflineSale',
      //   title: 'GH线下用药管理',
      //   icon: 'SolutionOutlined',
      //   type: 'menuItem',
      //   show: () => menuRoleaConifg.value.g1.has('GHOfflineSale'),
      // },
      {
        key: 'saleImportCheck',
        title: '用药导入校验',
        icon: 'SolutionOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('saleImportCheck'),
      },
      {
        key: 'saleDataManage',
        title: '合格用药管理',
        icon: 'SolutionOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('saleDataManage'),
      },
      {
        key: 'exportCenter',
        title: '导出管理',
        icon: 'DownloadOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('exportCenter'),
      },
      {
        key: 'patientImportCheck',
        title: '患者校验管理',
        icon: 'DownloadOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g1.has('patientImportCheck'),
      },
    ],
  },
  {
    key: 'g2',
    title: '配置管理',
    children: [
      {
        key: 'memberSub',
        title: '成员管理',
        icon: 'TeamOutlined',
        type: 'subMenu',
        show: () => menuRoleaConifg.value.g2.has('shortTermMember'),
        children: [
          {
            key: 'shortTermMember',
            title: '短期成员',
            type: 'menuItem',
            show: () => menuRoleaConifg.value.g2.has('shortTermMember'),
          },
        ],
      },
      {
        key: 'hospitalLabelList',
        title: '医院标签管理',
        icon: 'ShopOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g2.has('hospitalLabelList'),
      },
    ],
  },
  {
    key: 'g3',
    title: '问题反馈',
    children: [
      {
        key: 'problemFeedback',
        title: '问题反馈',
        icon: 'QuestionCircleOutlined',
        type: 'menuItem',
        show: () => menuRoleaConifg.value.g3.has('problemFeedback'),
      },
    ],
  },
];

/**
 * 最终渲染菜单
 */
export const menuGroupList = computed(() => {
  const result: MenuGroup[] = [];
  BaseMenuGroup.forEach((menuList) => {
    if (menuRoleaConifg.value[menuList.key].size > 0) {
      const children: typeof menuList.children = [];
      menuList.children.forEach((menu) => {
        if (menu.type === 'subMenu') {
          if (menu.show()) {
            const subMenuChildren: typeof menu.children = [];
            menu.children.forEach((subChild) => {
              if (subChild.show()) {
                subMenuChildren.push(subChild);
              }
            });
            children.push({ ...menu, children: subMenuChildren });
          }
        } else {
          if (menu.show()) {
            children.push(menu);
          }
        }
      });
      result.push({ ...menuList, children });
    }
  });
  return result;
});
