import { RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    name: 'taskCenter',
    path: 'taskCenter',
    component: () => import(/* webpackChunkName: "work-bench" */ `../views/manage/TaskCenter.vue`),
    meta: {
      h5Page: '/views/manage/layout',
      // 使用 key=value&key2=value2的形式传递
      h5Params: '',
    },
  },
  {
    name: 'exportCenter',
    path: 'exportCenter',
    component: () => import(/* webpackChunkName: "work-exportCenter" */ `../views/manage/ExportCenter.vue`),
    meta: {},
  },
  {
    name: 'transRecord',
    path: 'transRecord',
    component: () => import(/* webpackChunkName: "work-transRecord" */ `../views/manage/TransRecord.vue`),
    meta: {},
  },
  {
    name: 'patient',
    path: 'patient',
    component: () => import(/* webpackChunkName: "work-patient" */ `../views/manage/Patient.vue`),
    meta: {},
  },
  {
    name: 'formDel',
    path: 'formDel',
    component: () => import(/* webpackChunkName: "work-formDel" */ `../views/manage/FormDel.vue`),
    meta: {},
  },
  {
    name: 'KeyBoarderRel',
    path: 'KeyBoarderRel',
    component: () => import(/* webpackChunkName: "work-KeyBoarderRel" */ `../views/manage/KeyBoarderRel.vue`),
    meta: {},
  },
  {
    name: 'user',
    path: 'user',
    component: () => import(/* webpackChunkName: "work-user" */ `../views/manage/User.vue`),
    meta: {},
  },
  {
    name: 'addUser',
    path: 'addUser',
    component: () => import(/* webpackChunkName: "work-addUser" */ `../views/manage/AddUser.vue`),
    meta: {},
  },
  {
    name: 'upload',
    path: 'upload',
    component: () => import(/* webpackChunkName: "work-upload" */ `../views/manage/Upload.vue`),
    meta: {},
  },
  {
    name: 'longTermMember',
    path: 'longTermMember',
    component: () => import(/* webpackChunkName: "work-termMember" */ `../views/manage/BusinessMember/List.vue`),
    meta: { subName: 'memberSub' },
  },
  {
    name: 'shortTermMember',
    path: 'shortTermMember',
    component: () => import(/* webpackChunkName: "work-termMember" */ `../views/manage/BusinessMember/List.vue`),
    meta: { subName: 'memberSub' },
  },
  {
    name: 'hospitalLabelList',
    path: 'HospitalLabel/list',
    component: () => import(/* webpackChunkName: "work-saleImportCheck" */ `../views/manage/HospitalLabel/List.vue`),
    meta: {},
  },
  //sale系统内容，link系统暂不需要
  // {
  //   name: 'NourishmentSale',
  //   path: 'NourishmentSale',
  //   component: () => import(/* webpackChunkName: "work-NourishmentSale" */ `../views/manage/Nourishment/SaleList.vue`),
  //   meta: {},
  // },
  // {
  //   name: 'NourishmentSaleBatchImport',
  //   path: 'NourishmentSaleBatchImport',
  //   component: () =>
  //     import(/* webpackChunkName: "work-NourishmentSaleBatchImport" */ `../views/manage/Nourishment/BatchImport.vue`),
  //   meta: {},
  // },
  // {
  //   name: 'GHOfflineSale',
  //   path: 'GHOfflineSale',
  //   component: () => import(/* webpackChunkName: "work-GHOfflineSale" */ `../views/manage/GHOffline/SaleList.vue`),
  //   meta: {},
  // },
  // {
  //   name: 'GHOfflineAudit',
  //   path: 'GHOfflineAudit',
  //   component: () => import(/* webpackChunkName: "work-GHOfflineAudit" */ `../views/manage/GHOffline/AuditList.vue`),
  //   meta: {},
  // },
  // {
  //   name: 'GHOfflineBatchImport',
  //   path: 'GHOfflineBatchImport',
  //   component: () =>
  //     import(/* webpackChunkName: "work-GHOfflineBatchImport" */ `../views/manage/GHOffline/BatchImport.vue`),
  //   meta: {},
  // },
  {
    name: 'saleDataManage',
    path: 'saleDataManage',
    component: () => import(/* webpackChunkName: "work-SaleDataManage" */ `../views/manage/SaleDataManage.vue`),
    meta: {},
  },
  {
    name: 'saleImportCheck',
    path: 'saleImportCheck',
    component: () => import(/* webpackChunkName: "work-saleImportCheck" */ `../views/sale/ImportCheck/check.vue`),
    meta: {},
  },
  {
    name: 'patientImportCheck',
    path: 'patientImportCheck',
    component: () => import(/* webpackChunkName: "work-saleImportCheck" */ `../views/manage/PatientImportCheck.vue`),
    meta: {},
  },
  {
    name: 'problemFeedback',
    path: 'problemFeedback',
    component: () => import(/* webpackChunkName: "work-saleImportCheck" */ `../views/manage/problemFeedback.vue`),
    meta: {},
  },
];
export default routes;
