
  import { BusinessMemberListVo } from '@/type/api';
  import { Column, Option, TableState } from '@/type/common';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import { computed, defineComponent, onMounted, reactive, ref, toRefs, watchEffect } from 'vue';
  import { useRoute } from 'vue-router';
  import { MemberModalPO, MemberPermission } from '@/type/businessMember';
  import EditModal from './Edit.vue';
  import {
    getHospitalLabelList,
    disableBusinessMember,
    enableBusinessMember,
    hospitalLabelCancelRedPoint,
    getDictionaryList,
  } from '@/services/manage';
  import { message } from 'ant-design-vue';
  import moment from 'moment';
  export default defineComponent({
    name: 'BusinessMemberList',
    components: { ManageListFrame, CustomTable, EditModal },
    setup() {
      const route = useRoute();
      const isLongTerm = computed(() => (route.name === 'longTermMember' ? true : false));
      const editModal = ref<MemberModalPO>({ show: false });
      // 定义0到50的选项数组
      const options = Array.from({ length: 51 }, (_, i) => ({
        id: i,
        name: i.toString(),
      }));

      const organizations: any = ref([]);
      const query = reactive<any>({});

      const visible = ref(false);
      const isDisabled = ref(false);
      const userId: any = ref();
      const opts = reactive({
        provinceOpts: [] as any, // 省 下拉框
        cityOpts: [] as any, //  下拉框
        hospitalTypeList: [
          {
            name: '公立医院',
            value: '公立医院',
          },
          {
            name: '民营医院',
            value: '民营医院',
          },
        ] as any[], //角色下拉框
        stateOpts: [
          {
            name: '是',
            value: 1,
          },
          {
            name: '否',
            value: 0,
          },
        ] as any[], // 状态下拉框
      });

      const getColumns = () => {
        let columns: Column[] = [];
        const baseColumns: Column[] = [
          {
            title: '医院名称',
            dataIndex: 'hospitalName',
            width: 180,
            slots: {
              customRender: 'hospitalName',
            },
          },
          {
            title: '省份',
            dataIndex: 'provinceName',
            width: 60,
            ellipsis: true,
          },
          {
            title: '地级市',
            dataIndex: 'cityName',
            ellipsis: true,
            width: 60,
          },
          {
            title: '医院类型',
            dataIndex: 'hospitalType',
            width: 80,
            ellipsis: true,
          },
          {
            title: '是否儿外交叉医院',
            dataIndex: 'externalChiasmaStr',
            width: 130,
            ellipsis: true,
          },
          {
            title: '规格1',
            dataIndex: 'category1',
            width: 100,
            slots: {
              customRender: 'category1',
            },
          },
          { title: '是否特殊凭证', dataIndex: 'specialCertificateStr', width: 120, ellipsis: true },
          { title: '特殊凭证备注', dataIndex: 'remark', width: 120, ellipsis: true },
          {
            title: '是否超流向',
            dataIndex: 'superflowDirectionStr',
            width: 100,
            ellipsis: true,
          },
          {
            title: '规格2',
            dataIndex: 'category2',
            width: 100,
            slots: {
              customRender: 'category2',
            },
          },
          {
            title: '操作',
            dataIndex: 'action',
            fixed: 'right',
            width: 150,
            slots: {
              customRender: 'actions',
            },
          },
        ];
        // 长期成员 列表变更
        if (isLongTerm.value) {
          baseColumns.forEach((column) => {
            if (column.dataIndex === 'region') {
              column.title = '区域';
            }
            if (!['effectiveTime', 'action'].includes(column.dataIndex)) {
              columns.push(column);
            }
          });
        } else {
          columns = baseColumns;
        }
        return columns;
      };
      const tableState = reactive(
        new TableState({
          dataSource: [] as any,
          columns: getColumns(),
          key: 'id',
        }),
      );

      /**
       * 获取成员列表
       */
      const getData = () => {
        //过滤对象中的假值，但不包括0。
        const filteredObj = Object.fromEntries(
          Object.entries(query).filter(([key, value]) => value == 0||value != '' && value != null && value != undefined),
        );
        const params: any = {
          ...filteredObj,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        };
        if (query.dateRange) {
          params.startTime = moment(query.dateRange[0]).format('YYYY-MM-DD');
          params.endTime = moment(query.dateRange[1]).format('YYYY-MM-DD');
        }
        delete params.dateRange;
        getHospitalLabelList(params).then((data: any) => {
          tableState.pagination.total = data.total;
          tableState.dataSource = data.list;
        });
      };

      /**
       * 清空搜索框中内容
       */
      const clearQuery = () => {
        query.hospitalName = undefined; //成员
        query.provinceId = undefined; //账号状态
        query.cityId = undefined; //角色
        query.hospitalType = undefined; //权重
        query.externalChiasma = undefined; //工号
        query.specialCertificate = undefined; //省
        query.superflowDirection = undefined; //城市
        query.dateRange = undefined; //机构
        opts.cityOpts = [];
        getData();
      };

      /**
       * 处理 搜索
       */
      const handleSearch = () => {
        tableState.pagination.current = 1;
        getData();
      };

      /**
       * Table 换页
       */
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData();
      };

      /**
       *  关闭成员弹窗
       */
      const closeEditModal = (isReSearch: boolean) => {
        editModal.value = {
          show: false,
        };
        if (isReSearch) {
          handleSearch();
        }
      };

      /**
       *  打开添加成员弹窗
       */
      const openAddModal = () => {
        editModal.value = {
          show: true,
          type: 'create',
        };
      };

      /**
       *  打开更新成员弹窗
       */
      const openEditModal = (record: BusinessMemberListVo,type:any) => {
        editModal.value = {
          show: true,
          type,
          member: record,
        };
        handleRead(record);
      };
      /**
       * 打开状态修改弹窗
       */
      const openStatusModal = (status, userInfo) => {
        visible.value = true;
        isDisabled.value = status;
        userId.value = userInfo.id;
      };
      /**
       * 修改状态
       */
      const editStatus = () => {
        if (isDisabled.value) {
          disableBusinessMember({ id: userId.value }).then((res: any) => {
            if (res.ok) {
              message.success('停用成功');
              getData();
              visible.value = false;
            }
          });
        } else {
          enableBusinessMember({ id: userId.value }).then((res: any) => {
            if (res.ok) {
              message.success('启用成功');
              getData();
              visible.value = false;
            }
          });
        }
      };

      const getProvince = () => {
        getDictionaryList({ type: 'Province' }).then((res: any) => {
          opts.provinceOpts = res;
        });
      };

      const getCity = () => {
        handleSearch();
        if (query.provinceId) {
          getDictionaryList({ type: 'City', jinsaiParentId: query.provinceId }).then((res: any) => {
            opts.cityOpts = res;
          });
        } else {
          opts.cityOpts = [];
          query.cityId = null;
        }
      };

      const handleRead = (val: any) => {
        if (!val.hadRead) {
          hospitalLabelCancelRedPoint({ hospitalId: val.hospitalId }).then((res: any) => {
            getData();
          });
        }
      };

      /**
       * 页面挂载时 获取省列表
       */
      onMounted(() => {
        clearQuery();
        getData();
        getProvince();
      });
      /**
       * 重置按钮重置
       */
      const resetting = () => {
        clearQuery();
      };

      return {
        query,
        isLongTerm,
        tableState,
        editModal,
        ...toRefs(opts),
        handleSearch,
        handlePage,
        openAddModal,
        openEditModal,
        closeEditModal,
        MemberPermission,
        visible,
        openStatusModal,
        isDisabled,
        editStatus,
        organizations,
        options,
        resetting,
        getProvince,
        getCity,
        handleRead,
      };
    },
  });
