import { Moment } from 'moment';
import { BusinessMemberInfoVo, BusinessMemberListVo } from './api';

export interface MemberModalPO {
  show: boolean;
  type?: 'create' | 'edit'|'view';
  member?: BusinessMemberListVo;
}

export enum MemberRole {
  /** 2 - GH-录入员 */
  RECORDER = 'RECORDER',
  /** 3- GH-区域管理员 */
  REGION_ADMIN = 'REGION_ADMIN',
  /** 4 - GH-平台管理员 */
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  /** 5 - GH-用药划分 */
  // SALES_DIVISION = 'SALES_DIVISION',
  /** 6 - 营养品-录入员 */
  // NUTRITION_RECORDER = 'NUTRITION_RECORDER',
  /** 7 - 营养品-用药划分 */
  // NUTRITION_SALES_DIVISION = 'NUTRITION_SALES_DIVISION',
  /** 8 - GH线下-录入员 */
  // OFFLINE_RECORDER = 'OFFLINE_RECORDER',
  /** 9 - GH线下-用药划分 */
  // OFFLINE_SALES_DIVISION = 'OFFLINE_SALES_DIVISION',
  /** 10 - GH线下-用药审核 */
  // OFFLINE_SALES_AUDIT = 'OFFLINE_SALES_AUDIT',
}

export const MemberRole_Name: { [T in MemberRole]: string } = {
  [MemberRole.RECORDER]: '患者用药审核员',
  [MemberRole.REGION_ADMIN]: 'GH-区域管理员',
  [MemberRole.PLATFORM_ADMIN]: '平台管理员',
  // [MemberRole.SALES_DIVISION]: '用药划分员',
  // [MemberRole.OFFLINE_RECORDER]: '线下-录入员',
};

export const MemberPermission = ['录入审核', '查看'];
export interface MemberRegion {
  /** 省ID */
  provinceId: string;
  /** 省名称 */
  provinceName: string;
  /** 市ID */
  cityId: string;
  /** 市名称 */
  cityName: string;
}

export interface Organization {
  cityName?: string;
  provinceName?: string;
  hospitalName?: string;
  hospitalId?: any;
  provinceId?: any;
  cityId?: any;
}

export interface MemberHospital {
  /** 医院ID */
  hospitalId: string;
  /** 医院名称 */
  hospitalName: string;
}

export interface BusinessMemberInfoLocal extends BusinessMemberInfoVo {
  provinceIds: string[];
  cityIds: string[];
  hospitalIds: string[];
  dateRange: Moment[];
}
